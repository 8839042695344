<template>
    <div>
        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="data.data"
            :search="search"
            :loading="loading"
            fixed-header
            height="330"
            style="border: 0.5px solid #42A5F5; border-radius: 10px;"
        >
            <template v-slot:top>
                <v-row class="text-center">
                    <v-col class="text-center" cols="12" sm="12">
                        {{ data.title }}
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="2">
                        <emc-button-icon v-if="data.title && filter" :loading="loading" class="ml-2" icon="mdi-refresh" text="Atualizar" color="light-green" @click.prevent.stop="loadData()" />
                    </v-col>
                    <v-col cols="12" sm="10">
                        <v-text-field
                            v-model="search"
                            label="Digite aqui o nome da UC"
                            prepend-inner-icon="search"
                            class="ml-1 mr-1 mt-0"
                        />
                    </v-col>
                </v-row>
                
            </template>

            <template v-slot:item.discipline_name="{ item }">
                {{ item.discipline_name }}
                <br/>
                <small>{{ item.discipline.code }} - {{ item.discipline.work_load }}</small>
            </template>

        </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "TableTotalStudents",
    data () {
        return {
           data: {},
           search: '',
           loading: false,
           selected: [],
            headers: [
                { text: 'Componente Curricular', value: 'discipline_name' },
                { text: 'Composições', value: 'total_groups' },
                { text: 'Alunos', value: 'total_students' },
                { text: 'Grupo de Dias', value: 'total_days' },
            ],
        }
    },
    props: {
        filter: {

        },
    },
    
    methods: {
        ...mapActions('grid', ['ActionOfferItemsTableTotalUC']),

        loadData(){

            this.loading = true;
            
            this.ActionOfferItemsTableTotalUC(this.filter)
                .then((res) => {
                    this.data = res.data;
                }).finally(() => {
                    this.loading = false;
                    this.$emit('loaded');
                });
        },

    }
    
}
</script>