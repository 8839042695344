<template>
    <div>
        <v-simple-table :loading="true" fixed-header height="100%" style="border: 0.5px solid #42A5F5; border-radius: 10px;">
            <template v-slot:default>
                <caption>
                    {{ data.title }}
                    <v-progress-linear
                    v-if="loading"
                    indeterminate
                    color="blue"
                />
                </caption>
                <thead>
                    <tr>
                        <th class="pa-0 ma-0 text-center" style="width: 10px;">
                            
                        </th>
                        <th colspan="4" class="text-center" v-for="(d, index) in days" :key="index" >{{ d.name }}</th>
                    </tr>
                    <tr>
                        <th  class="pa-0 ma-0 text-center">
                            <emc-button-icon v-if="data.title && filter" :loading="loading" class="mr-2" icon="mdi-refresh" text="Atualizar" color="light-green" @click.prevent.stop="loadData()" />
                        </th>
                        <th class="pa-0 ma-0 text-center" v-for="n in data.numColumns" :key="n" :style="(n - 1) % 4 == 3 && n < data.numColumns ? 'border-right: 0.5px solid #42A5F5;' : ''">
                            
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" x-small v-if="(n - 1) % 4 == 0" v-bind="attrs" v-on="on">{{ icon.inPerson }}</v-icon>
                                </template>
                                <span> Presencial </span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon  x-small v-if="(n - 1) % 4 == 1" v-bind="attrs" v-on="on">{{ icon.digital }}</v-icon>
                                </template>
                                <span> Digital </span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon  color="primary"  x-small v-if="(n - 1) % 4 == 2" v-bind="attrs"  v-on="on">{{ icon.hybrid }}</v-icon>
                                </template>
                                <span> Híbrido </span>
                            </v-tooltip>

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="black" v-bind="attrs"  v-on="on"  x-small v-if="(n - 1) % 4 == 3">{{ icon.total }}</v-icon>
                                </template>
                                <span> Total </span>
                            </v-tooltip>
                            
                        </th>

                    </tr>
                    
                </thead>
                <tbody>
                    <tr v-for="(shift, indexShift) in data.data.values" :key="indexShift">
                        <td class="pa-0 ma-0 text-left"><small class="ml-1">{{ shifts[indexShift].name }}</small></td>
                        <template v-for="(day, indexDay) in shift">
                            <td class="pa-0 ma-0 text-center" v-for="(total, indexCategory) in day" :key="(indexShift + '-' + indexDay + '-' + indexCategory)" :style="indexCategory == 3 && indexDay < 5 ? 'border-right: 0.5px solid #42A5F5;' : ''" >
                                <span v-if="indexCategory == 0 || indexCategory == 2" class="blue--text" ><small>{{ total }}</small></span>
                                <span v-else-if="indexCategory == 1"><small>{{ total }}</small></span>
                                <strong v-else-if="indexCategory == 3"><small>{{ total }}</small></strong>
                                
                            </td>
                        </template>
                    </tr>
                </tbody>
                <tfoot mt-2>
                    <tr>
                        <td  class="pa-0 ma-0 text-left"><strong><small class="ml-1">Totais</small></strong></td>
                        <template v-for="(item, indexDay) in data.data.totals">
                            <td  class="pa-0 ma-0 text-center" v-for="(total, indexCol) in item" :key="indexDay + '-' + indexCol" :style="indexDay < 5 && indexCol == 3 ? 'border-right: 0.5px solid #42A5F5;' : ''">
                                <strong v-if="indexCol == 0 || indexCol == 2" class="blue--text" ><small>{{ total }}</small></strong>
                                <strong v-else-if="indexCol == 1"><small>{{ total }}</small></strong>
                                <strong v-else-if="indexCol == 3"><small>{{ total }}</small></strong>
                            </td>
                        </template>
                    </tr>
                    <tr>
                        <td  class="pa-0 ma-0 text-center"></td>
                        <td  class="pa-0 ma-0 text-center" colspan="4" v-for="(perc, index) in data.data.percents" :key="index" :style="index < 5 ? 'border-right: 0.5px solid #42A5F5;' : ''">
                            
                            <v-chip
                                color="blue lighten-1"
                                dark
                                class="justify-center ma-2"
                                style="width: 50px;"
                            >
                                <strong><small>{{ perc }}</small></strong>
                            </v-chip>
                        </td>
                    </tr>
                </tfoot>
            </template>
        </v-simple-table>
    </div>
</template>

<style scoped>

.table table th {
    width: 10px;
    height: 20px;
}

</style>

<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "TableTotalDays",
    data () {
        return {
           data: {
            title: '',
            numColumns: 0,
            data: {
                values: [],
                total: []
            }
           },
           loading: false,
           icon: {
            inPerson: 'mdi-chair-school',
            digital: 'mdi-desktop-classic',
            hybrid: 'mdi-gesture-double-tap',
            total: 'mdi-code-equal'
           },
           days: [
            { id: 2, name: 'SEG' },
            { id: 3, name: 'TER' },
            { id: 4, name: 'QUA' },
            { id: 5, name: 'QUI' },
            { id: 6, name: 'SEX' },
            { id: 7, name: 'SÁB' },
           ],
           shifts: [
            { id: 1, name: 'Manhã' },
            { id: 2, name: 'Tarde' },
            { id: 3, name: 'Noite' },
            { id: 4, name: 'Integral' },
            { id: 5, name: 'Digital' },
           ],
        }
    },
    props: {
        filter: {

        },
    },
    methods: {
        ...mapActions('grid', ['ActionOfferItemsTableTotalUCDay']),

        loadData(){

            this.loading = true;
            
            this.ActionOfferItemsTableTotalUCDay(this.filter)
                .then((res) => {
                    this.data = res.data;
                }).finally(() => {
                    this.loading = false;
                    this.$emit('loaded');
                });
        },

        getIcon(index){

            switch (index) {
                case 0: return this.icon.inPerson;
                case 1: return this.icon.digital;
                case 2: return this.icon.hybrid;
                case 3: return this.icon.total;
            }

        },
    }
    
}
</script>