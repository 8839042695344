<template>
    <div>
        <emc-chart-apex-base v-if="renderComponent" :loading="loading" :data="data" @refresh="loadData()" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: 'ChartEvolution',
    data (vm) {
        return {
            loading: false,
            renderComponent: true,
            data: {
                type: 'pie',
                title: 'Acompanhe sua oferta',
                labels: [],
                series: []
            },
        }
    },

    mounted() {
        
    },

    props: {
       filter: {
            required: true,
       },
    },


    methods: {
        ...mapActions('grid', ['ActionOfferItemsIndicatorEvolution']),

        loadData(){

            this.loading = true;
            
            this.ActionOfferItemsIndicatorEvolution(this.filter)
            .then((res) => {
                this.data = res.data;
            }).catch((error) =>{
               
            })
            .finally(() => {
                this.loading = false;
                this.$emit('loaded');
                this.forceRerender();

            });

        },

        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
            });
    
          },
        
    },
    
}
</script>