import TableTotalDays from '../components/TableTotalDays.vue';
import TableTotalStudents from '../components/TableTotalStudents.vue';
import TableGroups from '../components/TableGroups.vue';
import ChartEvolution from '../components/ChartEvolution.vue';
import ChartHybridity from '../components/ChartHybridity.vue';

export default {
  data: (vm) => ({
    loadingProgress: false,
    filter: {
      orderTableGroups: 'discipline',
      keyDiscipline: 'name',
    },
    renderComponentFilter: true,
    loadingFilterGroup: false,
    selectedFilterGroup: null,
    error: false,
    expanded: false,
    tableExpanded: true,
    errors: [],
    loadingData: false,
    loadingTableGroup: false,
    totalInconsistences: 0,
    totalLoaded: 0,
    showTools: true,
    config: {
      validate_time_table: true,
      validate_ucdp: true,
    },
  }),
  components: {
    TableTotalDays,
    TableTotalStudents,
    TableGroups,
    ChartEvolution,
    ChartHybridity
},
  computed: {
    disabledButtons(){
      return (!this.filter || !this.filter.brands || (this.filter.brands && this.filter.brands.length == 0)) || 
              (!this.filter.occupationAreas || (this.filter.occupationAreas && this.filter.occupationAreas.length == 0)) || 
              (!this.filter.shifts || (this.filter.shifts && this.filter.shifts.length == 0));
    }

  },

  mounted() {
    this.$refs.chartEvolution.loadData();
    this.$refs.chartHybridity.loadData();
  },

  methods: {

    loadData(){

      this.$nextTick(() => {
        
        this.loadingData = true;
        this.totalLoaded = 0;

        this.$refs.chartEvolution.loadData();
        this.$refs.chartHybridity.loadData();
        this.$refs.tableTotalDays.loadData();

        if(!this.disabledButtons){
          this.$refs.tableTotalStudents.loadData();
          this.$refs.tableGroups.loadData();
        }

        
      });

    },

    loadedTableGroups(force, ev){

      this.loadingTableGroup = true;
      this.totalInconsistences = ev;
      this.loaded(force);

    },

    loaded(force = false){
      
      this.totalLoaded++;

      if(force || this.totalLoaded >= 3){
        this.loadingData = false;
        this.totalLoaded = 0;
      }

      this.loadingFilterGroup = false;
      
    },

    clearFilter(){
      
      this.$nextTick(() => {
        this.filter = {
          orderTableGroups: (this.filter && this.filter.orderTableGroups) ? this.filter.orderTableGroups : 'discipline',
          keyDiscipline: (this.filter && this.filter.keyDiscipline) ? this.filter.keyDiscipline : 'name',
          table: this.filter.table
        };
      });
      
      
    },

    isFiltered(filter){

      return this.filter && this.filter.table && (this.filter.table[filter] == 'true' || this.filter.table[filter] == true);

    },

    getColorFilterTable(filter){

      if(this.isFiltered(filter)){
        return filter == 'inconsistency' ? 'red' : 'light-blue';
      }else{
        return 'gray';
      }

    },

    loadFilterTable(filter){

      this.selectedFilterGroup = filter;
      
      if(this.isFiltered(filter)){
        this.filter.table[filter] = false;
      }else{

        if(!this.filter || !this.filter.table){
          this.filter.table = {};
        }

        this.filter.table[filter] = true;

      }

      if(filter == 'classroom'){
        this.filter.table.digital = false;
        this.filter.table.hybrid = false;
      }

      if(filter == 'digital'){
        this.filter.table.classroom = false;
        this.filter.table.hybrid = false;
      }

      if(filter == 'hybrid'){
        this.filter.table.classroom = false;
        this.filter.table.digital = false;
      }

      if(filter == 'freshman'){
        this.filter.table.integralized = false;
      }

      if(filter == 'integralized'){
        this.filter.table.freshman = false;
      }

      this.loadingFilterGroup = true;
      this.forceRerender();
      this.$refs.tableGroups.loadData();

    },

    forceRerender () {
            
      this.renderComponentFilter = false;

      this.$nextTick(() => {
          this.renderComponentFilter = true;
      });

  },
    
    loadDataSelects(ev, item = null){

      
      this.$nextTick(() => {
        
        if(this.disabledButtons){
          return;
        }

        if(!item || item != 'disciplines'){
          this.$refs.disciplines.loadData(this.filter);
        }

        if(!item || item != 'groups'){
          this.$refs.groups.loadData(this.filter);
        }

        if(!item || item != 'campuses'){
          this.$refs.campuses.loadData(this.filter);
        }

        if(!item || item != 'courses'){
          this.$refs.courses.loadData(this.filter);
        }

        if(!item || item != 'qualifiers'){
          this.$refs.qualifiers.loadData(this.filter);
        }

        if(!item || item != 'categories'){
          this.$refs.categories.loadData(this.filter);
        }

        if(!item || item != 'workTimes'){
          this.$refs.workTimes.loadData(this.filter);
        }

        if(!item || item != 'offerItemDays'){
          this.$refs.offerItemDays.loadData(this.filter);
        }

        if(!item || item != 'offerStatuses'){
          this.$refs.offerStatuses.loadData(this.filter);
        }

        if(!item || item != 'baseGroupStatuses'){
          this.$refs.baseGroupStatuses.loadData(this.filter);
        }



      });

    },

    
    
  },
};