<template>
    <div fluid ma-0 pa-0>
        <v-data-table
            :headers="headers"
            :items="data.data"
            fixed-header
            height="500"
            :loading="loading"
        >
        
            <template v-slot:header.campus_name="{ header }">
                <small>Linhas: {{ data.data.length }}</small>
                <br/>
                {{ header.text }}
            </template>

            <template v-slot:header.course_name="{ header }">
                <small>UC's: {{ data.columns.length }}</small>
                <br/>
                {{ header.text }}
            </template>

            <template v-for="(col, index) in data.columns" v-slot:[`header.${col.value}`]="{ header }">
                <span  :key="index">
                    
                    <strong class="orange--text">{{ totalStudents(header.value, true) }}</strong>
                    <span v-if="expanded">|</span><br v-else/>
                    <strong class="green--text">{{ totalStudents(header.value, false) }}</strong>
                    <span v-if="expanded">|</span>

                    <span v-if="expanded">
                        <v-icon x-small color="black" dark>mdi-calculator</v-icon>: <strong>{{ somas[header.value] }}</strong>
                        <br/>
                    </span>
                    <strong v-else><br/>{{ somas[header.value] ? somas[header.value] : 0 }}<br/></strong>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <small v-bind="attrs" v-on="on">
                                <span v-if="expanded">
                                    {{ header.text.length > 23 ? header.text.substring(0, 23 ) + '...' : header.text }}
                                </span>
                                <span v-else>
                                    {{ header.text.substring(0, 4) }}
                                </span>
                                
                            </small>
                        </template>
                        <span> {{ header.text }} </span>
                    </v-tooltip>

                    <br/>
                    <v-icon
                        v-if="renderComponent" 
                        @click.prevent.stop="selectAllItem(header.value, true)"
                        :color="color.cursar"
                        small
                    >
                        {{ selectedAll[header.value] && selectedAll[header.value].cursar ? 'mdi-checkbox-marked-circle' : 'mdi-circle-outline' }}
                    </v-icon>
                    <v-icon
                        v-if="renderComponent" 
                        @click.prevent.stop="selectAllItem(header.value, false)"
                        :color="color.ofertar"
                        small
                    >
                        {{ selectedAll[header.value] && selectedAll[header.value].ofertar ? 'mdi-checkbox-marked-circle' : 'mdi-circle-outline' }}
                    </v-icon>
                    <v-btn :disabled="!totalSelectedByCol[header.value] || totalSelectedByCol[header.value] <= 1" icon x-small @click.prevent.stop="editMultiItem(header.value)"><v-icon color="primary">mdi-pencil-outline</v-icon></v-btn>
                </span>
                
            </template>
            <template v-slot:item.notifications="{ item }">
                <v-row>
                    
                    <div class="ma-0 pa-0" >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn x-small v-bind="attrs" v-on="on" icon :href="'/offer_grids?code=' + item.base_group_code" target="_blank">
                                    <v-icon x-small color="green">mdi-arrow-right-bold-circle-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>Ir para catálogo</span>
                        </v-tooltip>
                    </div>

                    <s-icon v-if="item.notifications.inconsistency && (filter && filter.notifications)" tool-tip="Inconsistências"  :color="(filter && filter.table && filter.table.inconsistency)  ? 'red' : 'gray'"    x-small icon="mdi-alert-outline" @click="loadInconsistencies(item)" :loading="loadingInconsitencies && selectedItem.id == item.id" />
                    
                    <s-icon v-if="item.notifications.merge"         tool-tip="Merge"            :color="(filter && filter.table && filter.table.merge)          ? 'light-blue' : 'gray'"    x-small icon="mdi-flag-variant"/>
                    <s-icon v-if="item.notifications.update"        tool-tip="Atualizações"     :color="(filter && filter.table && filter.table.update)         ? 'light-blue' : 'gray'"    x-small icon="mdi-information-outline" />
                    <s-icon v-if="item.notifications.classroom"     tool-tip="Presencial"       :color="(filter && filter.table && filter.table.classroom)      ? 'light-blue' : 'gray'"    x-small icon="mdi-office-building" />
                    <s-icon v-if="item.notifications.digital"       tool-tip="Digital"          :color="(filter && filter.table && filter.table.digital)        ? 'light-blue' : 'gray'"    x-small icon="mdi-desktop-classic" />
                    <s-icon v-if="item.notifications.hybrid"        tool-tip="Híbrido"          :color="(filter && filter.table && filter.table.hybrid)         ? 'light-blue' : 'gray'"    x-small icon="mdi-gesture-double-tap" />
                    <s-icon v-if="item.notifications.freshman"      tool-tip="Calouro"          :color="(filter && filter.table && filter.table.freshman)       ? 'light-blue' : 'gray'"    x-small icon="mdi-star-box-multiple-outline" />
                    <s-icon v-if="item.notifications.integralized"  tool-tip="Formando"         :color="(filter && filter.table && filter.table.integralized)   ? 'light-blue' : 'gray'"    x-small icon="mdi-school-outline" />
                    <s-icon v-if="item.notifications.algorithm"     tool-tip="Algoritmo"        :color="(filter && filter.table && filter.table.algorithm)      ? 'light-blue' : 'gray'"    x-small icon="mdi-codepen" />
                </v-row>
            </template>

            <template v-slot:item.campus_name="{ item }">
                <span v-if="expanded">
                    {{ item.campus_name }}
                    <br/>
                    <small>{{ item.brand_name }}</small>
                </span>
                <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ formatTextCell(item.campus_name) }}</span>
                    </template>
                    <span>
                        {{ item.campus_name }}
                        <br/>
                        {{ item.brand_name }}
                    </span>
                </v-tooltip>
            </template>

            <template v-slot:item.course_name="{ item }">
                
                <span v-if="expanded">
                    {{ item.course_name }}
                    <br/>
                    <small>{{ item.area_name }}</small>
                    <small v-if="item.category_name"><br/>{{ item.category_name }}</small>
                </span>
                <v-tooltip v-else bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{ formatTextCell(item.course_name) }}</span>
                    </template>
                    <span>
                        {{ item.course_name }}
                        <br/>
                        {{ item.area_name }}
                        <span v-if="item.category_name"><br/>{{ item.category_name }}</span>
                    </span>
                </v-tooltip>
            </template>

            <template v-slot:item.base_group_name="{ item }">
                <td class="column-fixed z-index-cell">
                    <span v-if="expanded">
                        {{ item.base_group_name }}
                        <br/>
                        <small>{{ item.base_group_code }}</small>
                        <br/>
                        <small>{{ item.shift_name }}</small>
                    </span>
                    <v-tooltip v-else bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">{{ formatTextCell(item.base_group_name) }}</span>
                        </template>
                        <span>
                            {{ item.base_group_name }}
                            <br/>
                            {{ item.base_group_code }}
                            <br/>
                            {{ item.shift_name }}
                        </span>
                    </v-tooltip>
                </td>
            </template>

            <template v-slot:item.total_completed_periods="{ item }">
                {{ item.periods }}
            </template>

            <template v-slot:item.hybridity_percent="{ item }">
                {{ item.hybridity_percent | percent }}
            </template>


            <template v-for="(col, index) in data.columns" v-slot:[`item.${col.value}`]="{ item }">
                <span :key="index">
                    
                    <span v-if="item[col.value]">
                        <span v-if="expanded">
                            <v-icon
                                    v-if="renderComponent && (!item[col.value].offer_item_status_id || item[col.value].offer_item_status_id == 3)" 
                                    @click="selectItem(col.value, item[col.value].id)"
                                    :color="getColor(item[col.value])"
                                    small
                                >
                                {{ selected.includes(item[col.value].id) ? 'mdi-checkbox-marked-circle' : 'mdi-circle-outline' }}
                            </v-icon>
                            <v-icon v-else small color="primary">mdi-lock-check</v-icon>
                            
                                <span :class="getColor(item[col.value]) + '--text'">
                                    
                                    <strong class="ml-1">{{ item[col.value].status_name }}</strong>
                                    <v-btn class="ml-1" :disabled="totalSelectedByCol[col.value] > 1" v-if="!item[col.value].offer_item_status_id || item[col.value].offer_item_status_id == 3" icon x-small @click.prevent.stop="editItem(item[col.value], null)"><v-icon color="primary">mdi-pencil-outline</v-icon></v-btn>
                                    <!-- <v-btn v-if="item[col.value].offer_item_status_id == 3" icon x-small @click.prevent.stop="editItem(item[col.value], -1)"><v-icon color="error">mdi-delete-outline</v-icon></v-btn> -->
                                    <small ><br/>({{ item[col.value].students }}) </small>
                                    <small v-if="item[col.value].join_category_name">{{ item[col.value].join_category_name }}</small>
                                    <small v-if="item[col.value].modality_name"><br/>{{ item[col.value].modality_name }}</small>
                                    <small v-if="item[col.value].day_name"><br/>{{ item[col.value].day_name }}</small>
                                    <small v-if="item[col.value].work_time_offer"><br/>{{ item[col.value].work_time_offer }}</small>

                                </span>

                        </span>
                        <v-tooltip v-else bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <v-icon
                                        v-if="renderComponent && (!item[col.value].offer_item_status_id || item[col.value].offer_item_status_id == 3)" 
                                        
                                        @click="selectItem(col.value, item[col.value].id)"
                                        :color="getColor(item[col.value])"
                                        small
                                    >
                                        {{ selected.includes(item[col.value].id) ? 'mdi-checkbox-marked-circle' : 'mdi-circle-outline' }}
                                    </v-icon>
                                    <v-icon v-else small color="primary">mdi-lock-check</v-icon>
                                    <v-btn :disabled="totalSelectedByCol[col.value] > 1" v-if="!item[col.value].offer_item_status_id || item[col.value].offer_item_status_id == 3" icon x-small @click.prevent.stop="editItem(item[col.value], null)"><v-icon color="primary">mdi-pencil-outline</v-icon></v-btn>
                                </span>
                            </template>
                            <span>
                                <strong class="ml-1">{{ item[col.value].status_name }}</strong>
                                <br/>({{ item[col.value].students }})
                                <span v-if="item[col.value].join_category_name">{{ item[col.value].join_category_name }}</span>
                                <span v-if="item[col.value].modality_name"><br/>{{ item[col.value].modality_name }}</span>
                                <span v-if="item[col.value].day_name"><br/>{{ item[col.value].day_name }}</span>
                                <span v-if="item[col.value].work_time_offer"><br/>{{ item[col.value].work_time_offer }}</span>
                            </span>
                        </v-tooltip>

                    </span>

                    <span class="text-center" v-else>
                        ---
                    </span>

                    
                </span>
            </template>

        </v-data-table>
        <sys-register-offer-item 
            :model="model"
            :models="models"
            @onCompleted="onCompletedRegister" 
            register-type="offer" 
            v-bind:show.sync="showForm" 
            title="Edição de Oferta"
            :status-default="statusDefault"
            :config="config"
        />

        <v-dialog
            v-model="dialogConsistency"
            max-width="290"
        >
        <v-card>
            <v-card-title class="text-h5">
                Inconsistências
            </v-card-title>

            <v-card-text>
                <ul>
                    <li v-for="(item, index) in dataInconsistencies " :key="index">
                        {{ item }}
                    </li>
                </ul>
            </v-card-text>

            <v-card-actions>

            <v-spacer></v-spacer>

            <v-btn
                color="green darken-1"
                text
                @click="dialogConsistency = false"
            >
                Fechar
            </v-btn>
            
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<style>
    .column-fixed {
        position: sticky;
        left: 0;
        z-index: 3 !important;
        background-color: white;
    }

    .z-index-cell {
        z-index: 1 !important;
    }

    tr:hover td {
        background-color: #ECEFF1;
    }
    

</style>

<script>
import { mapActions, mapState } from "vuex";
export default {
    name: "TableGroups",
    data () {
        return {
            data: {},
            model: {},
            models: [],
            renderComponent: true,
            loading: false,
            selected: [],
            showForm: false,
            selectedAll: {},
            selectedItem: null,
            dialogConsistency: false,
            dataInconsistencies: [],
            loadingInconsitencies: false,
            totalSelectedByCol: {},
            statusDefault: null,
            somas: {},
            color: {
                cursar: 'orange',
                ofertar: 'green',
                ofertarOutro: 'red',
                ofertado: 'blue',
            },
            headers: [],
            headersBase: [
                { text: '', value: 'notifications', width: '1%', sortable: true },
                { text: 'Marca/Campus', value: 'campus_name', width: '1%', sortable: true },
                { text: 'Curso/Área', value: 'course_name', width: '1%',  sortable: true },
                { text: 'Composição/Turno', value: 'base_group_name', width: '1%', sortable: true, class: 'column-fixed' },
                { text: 'Períodos', value: 'total_completed_periods', width: '1%', sortable: true },
                { text: '% Hibridez', value: 'hybridity_percent', width: '1%', sortable: true },
            ],
        }
    },
    props: {
        filter: {

        },
        config: {

        },
        expanded: {
            type: Boolean,
            default: true,
        }
    },

    computed: {
    ...mapState('auth', ['acesso']),
    },

    watch: {
        expanded(val){
            this.loadHeaders();
        }
    },
    
    methods: {
        ...mapActions('grid', ['ActionOfferItemsTableGroups', 'ActionShowOfferItemInconsistencies']),

        loadData(){

            if(!this.filter){
                return;
            }

            this.selected = [];
            this.loading = true;
            this.selectedAll = {},
            this.totalSelectedByCol = {},
            this.selected = [];
            
            this.ActionOfferItemsTableGroups(this.filter)
                .then((res) => {
                    
                    this.data = res.data;
                    this.loadHeaders();

                }).finally(() => {
                    this.loading = false;
                    let total = (this.data && this.data.inconsistencies) ? this.data.inconsistencies : 0
                    this.$emit('loaded', total);
                });
        },

        loadInconsistencies(item){

            this.loadingInconsitencies = true;
            this.selectedItem = item;

            this.ActionShowOfferItemInconsistencies({ id: item.id })
                .then((res) => {
                    this.dataInconsistencies = res.data;
                    this.dialogConsistency = true;
                }).finally(() => {
                    this.loadingInconsitencies = false;
                });

        },

        loadHeaders(){

            let headers2 = [];

            this.headersBase.forEach(h => {
                headers2.push(h);
            });

            this.data.columns.forEach(col => {
                let width = this.expanded ? 180 : 1;
                headers2.push(
                    { text: col.text, value: col.value, width: width },
                );
            });

            this.headers = headers2;

        },

        onCompletedRegister(){
            this.showForm = false;
            this.config.validate_time_table = true;
            this.config.validate_ucdp = true;
            this.loadData();
        },

        formatTextCell(text, length = 10){
            return text.length > length ? text.substring(0, length) + '...' : text;
        },

        getColor(item){

            if(!item.offer_item_status_id){
                return this.color.cursar;
            }else if(item.offer_item_status_id == 3){
                
                if(item.work_time_control && item.work_time_control != this.acesso.periodo.name){
                    return this.color.ofertarOutro;
                }else{
                    return this.color.ofertar;
                }
            }else{
                return this.color.ofertado;
            }

        },

        totalStudents(col, cursar){
            
            let sum = 0;

            this.data.data.forEach(item => {

                if(item[col]){

                    if(cursar){
                        if(!item[col].offer_item_status_id){
                            sum += item[col].students;
                        }
                    }else{
                        if(item[col].offer_item_status_id == 3){
                            sum += item[col].students;
                        }
                    }
                }

            });

            return sum;

        },

        somarOferta(col){
            
            let sum = 0;

            this.data.data.forEach(item => {

                if(item[col] && this.selected.includes(item[col].id)){
                    sum += item[col].students;
                }

            });

            this.somas[col] = sum;

        },

        editItem(item, status){
            let temp = {};
            Object.assign(temp, item);
            this.model = temp;
            this.models = [];
            this.statusDefault = status;
            this.showForm = true;
        },

        selectAllItem(col, cursar){
            
            let value;

            if(!this.selectedAll.hasOwnProperty(col)){
                this.selectedAll[col] = {
                    cursar: false,
                    ofertar: false,
                };
            }
            
            if(cursar){
                value = !this.selectedAll[col].cursar;
                this.selectedAll[col].cursar = value;
            }else{ 
                value = !this.selectedAll[col].ofertar;
                this.selectedAll[col].ofertar = value;
                
            }

            this.loadSelected(col, cursar, value);
            this.somarOferta(col);
            this.forceRerender();

        },

        loadSelected(col, cursar, ativar){

            this.data.data.forEach(item => {
                
                if(item[col]){
                    
                    let oferta = item[col];
                    let index = oferta.id;
                    
                    if(cursar && oferta.offer_item_status_id == 3){
                        return;
                    }

                    if(!cursar && (!oferta.offer_item_status_id || oferta.offer_item_status_id != 3)){
                        return;
                    }
                    
                    if(!oferta.offer_item_status_id || oferta.offer_item_status_id == 3){
                        if(ativar){
                        if(!this.selected.includes(index)){
                            this.pushSelected(index, col, true);
                        }
                        }else{
                            if(this.selected.includes(index)){
                                this.pushSelected(index, col, false);
                            }
                        }
                    }
                }
            })

        },

        selectItem(col, index){

            if(this.selected.includes(index)){
                this.pushSelected(index, col, false);
            }else{
                this.pushSelected(index, col, true);
            }

            this.somarOferta(col)

            this.forceRerender();

        },

        pushSelected(index, col, push){

            if(push){
                this.selected.push(index);

                if(!this.totalSelectedByCol[col]){
                    this.totalSelectedByCol[col] = 1;
                }else{
                    this.totalSelectedByCol[col]++;
                }

            }else{
                
                this.selected.splice(this.selected.indexOf(index), 1);

                if(this.totalSelectedByCol[col]){
                    this.totalSelectedByCol[col]--;
                }

            }

        },

        editMultiItem(col){

            let items = [];
            
            this.data.data.forEach(item => {
                
                if(item[col]){

                    if(this.selected.includes(item[col].id)){
                        items.push(item[col]);
                    }

                }

            });

            this.model = {};
            this.models = items;
            this.showForm = true;

        },

        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
            });
    
          },

    }
    
}
</script>