<template>
    <div>
        <emc-chart-apex-base v-if="renderComponent" :loading="loading" :data="data" @refresh="loadData()" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: 'ChartHybridity',
    data (vm) {
        return {
            loading: false,
            renderComponent: true,
            data: {
                type: 'pie',
                title: 'Hibridez nesta oferta',
                labels: [],
                series: []
            },
        }
    },

    mounted() {
        
    },

    props: {
       filter: {
            required: true,
       },
    },


    methods: {
        ...mapActions('grid', ['ActionOfferItemsIndicatorHybridity']),

        loadData(){

            this.loading = true;
            
            this.ActionOfferItemsIndicatorHybridity(this.filter)
            .then((res) => {
                this.data = res.data;
            }).catch((error) =>{
                
            })
            .finally(() => {
                this.loading = false;
                this.$emit('loaded');
                this.forceRerender();
            });

        },

        forceRerender () {
            
            this.renderComponent = false;
    
            this.$nextTick(() => {
                this.renderComponent = true;
            });
    
          },
        
    },
    
}
</script>